<template>
  <div>
    <Modal :options="{close:true}" @close="cerrar_modal">
      <div class="title">{{ buscar_nombre(documento.tipo_documento) }}</div>
      <div class="body">
        <div class="row mb-3">
          <div class="col-sm-3 offset-sm-9">
              <select v-model="ver_solo_validos" name="visualisar_versiones" id="visualisar_versiones" ref="visualisar_versiones" class="form-control">
                <option value="1">Ver solo activos</option>
                <option value="0">Ver todos</option>
              </select>
          </div>
        </div>

        <div v-if="versiones.length > 0" class="accordion" id="documento_versiones">
          <div v-for="version in versiones" :key="version.id" class="card">
            <div class="card-header" :id="'heading'+version.id">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#version-'+version.id" aria-expanded="true" :aria-controls="'version-'+version.id">
                  Documento versión #{{ version.version }}
                </button>
              </h2>
            </div>

            <div :id="'version-'+version.id" :class="'collapse'+(version.id == documento.id ? ' show' : '')" :aria-labelledby="'heading'+version.id" data-parent="#documento_versiones">
              <div class="card-body">
                <ul>
                  <li>Nombre: {{ version.nombre_original }}</li>
                  <li>Hash: {{ version.hash }}</li>
                  <li>Usuario: {{ version.usuario }}</li>
                  <li>Fecha de subida: {{ $moment(version.created_at).format('YYYY-MM-DD HH:mm:ss') }}</li>
                  <li>Tipo: {{ version.extension }}</li>
                  <li>Peso: {{ mejorar_peso_archivos(version.peso) }}</li>
                  <li>Versión: {{ version.version }}</li>
                  <li>Estatus: {{ version.estatus == 1 ? 'Valido' : 'Invalido' }}</li>
                </ul>

                <div class="row">
                  <div v-if="$auth.can('valija','solicitudes_documentos_descagar')" class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="descargar_documento(version)">Descargar</button></div>
                  <div v-if="version.estatus == 1 && $auth.can('valija','solicitudes_documentos_bloquar')" class="col-sm-3"><button class="btn warning-btn" @click="bloquear(version)">Marcar como Invalido</button></div>
                  <div v-if="version.estatus == 0 && $auth.can('valija','solicitudes_documentos_desbloquear')" class="col-sm-3"><button class="btn principal-btn" @click="desbloquear(version)">Marcar como Valido</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="accordion" id="documentos_versiones">
          No hay versiones {{ ver_solo_validos == 1 ? 'válidas' : 'invalidas' }} para este documento
        </div>
      </div>
      <div class="footer"></div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/valija/api/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      documento: {
        type: Object,
        default: function() {
          return {
            solicitud_id: 0
            ,nombre_original: null
            ,id: null
            ,extension: null
            ,mime: null
            ,hash: null
            ,peso: null
            ,tipo_documento: null
            ,usuario: null
            ,estatus: 0
          }
        }
      }
      ,catalogo: {
        type: Object,
        default: function() {
          return {
            nombre: null
            ,codigo: null
            ,opciones: []
          }
        }
      }
    }
    ,data: function() {
      return {
        versiones: []
        ,ver_solo_validos: 1
      }
    }
    ,mounted: function() {
      this.obtener_versiones();
    }
    ,methods: {
      obtener_versiones: async function() {
        try {
          this.versiones = (await api.obtener_documento_versiones(this.documento.solicitud_id, this.documento.id, this.$refs.visualisar_versiones.value)).data
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
      ,buscar_nombre: function(valor) {
        let nombre = null;
        let opciones = this.catalogo.opciones;

        for(let i=0; i<opciones.length; i++) {
          if (opciones[i].valor == valor) {
            nombre = opciones[i].nombre;
            i=opciones.length;
          }
        }

        return nombre;
      }
      ,mejorar_peso_archivos: function(bytes) {
        if (bytes >= 1000000000)
          return Math.ceil(((bytes / 1000) / 1000) / 1000)+'gb';

        if (bytes >= 1000000)
          return Math.ceil((bytes / 1000) / 1000)+'mb';

        if (bytes > 1000)
          return Math.ceil(bytes / 1000)+'kb';

        return bytes+'bytes';
      }
      ,descargar_documento: async function(documento) {
        try {
          let res = (await api.descargar_documento(documento.solicitud_id, documento.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',documento.nombre_original);
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,bloquear: async function(version) {
        try {
          let res = (await api.documento_bloquear(version.solicitud_id, version.id)).data;
          this.$log.info('bloquear',res);
          this.obtener_versiones();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,desbloquear: async function(version) {
        try {
          let res = (await api.documento_desbloquear(version.solicitud_id, version.id)).data;
          this.$log.info('desbloquear',res);
          this.obtener_versiones();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,watch: {
      ver_solo_validos() {
        this.obtener_versiones();
      }
    }
  }
</script>