<template>
  <div>
    <Modal :options="{width: '90vw',close:true,esc:false}" @close="cerrar_modal">
      <div class="title">Solicitud {{ solicitud_id+(solicitud.estatus==0?' - BLOQUEADA':'') }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12">
            <button v-if="solicitud.estatus == 1 && $auth.can('valija','solicitudes_bloquear')" class="btn btn-danger float-right" @click="bloquear_solicitud">Bloquear solicitud</button>
            <button v-if="solicitud.estatus == 0 && $auth.can('valija','solicitudes_desbloquear')" class="btn btn-success float-right" @click="desbloquear_solicitud">Desbloquear solicitud</button>
            <!-- <button v-if="$auth.can('valija','solicitudes_documentos_cargar')" :class="'btn btn-info float-right mr-2'+(solicitud.estatus == 0?' disabled':'')" :disabled="solicitud.estatus == 0" @click="modal_cargar=true">Cargar documento(s) {{ (solicitud.estatus==0?' - BLOQUEADA':'') }}</button> -->
            <button class="btn btn-warning float-right mr-2" @click="obtener_solicitud">Recargar</button>
          </div>
        </div>
        <div class="row">
          <div v-for="(documento, index) in solicitud.documentos" :key="documento.id" class="col-sm-4">
            <div class="card mt-4">
              <div class="card-body">
                <h5 class="card-title">{{ buscar_nombre(documento.tipo_documento) }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ documento.nombre_original }}</h6>
                <div class="card-text">
                  <ul>
                    <li>Tipo: {{ documento.extension }}</li>
                    <li>Peso: {{ mejorar_peso_archivos(documento.peso) }}</li>
                    <li>Versión: {{ documento.version }}</li>
                    <li>Estatus: {{ documento.estatus == 1 ? 'Valido' : 'Invalido' }}</li>
                  </ul>
                </div>
                <a v-if="$auth.can('valija','solicitudes_documentos_descagar')" href="#" class="card-link" @click="descargar_documento(documento)">Descargar archivo</a>
                <a href="#" class="card-link" @click="ver_documento(documento)">Detalles</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Documento v-if="modal_documento" :documento="documento" :catalogo="catalogo" @close="cerrar_modal_documento" />
    <Cargar v-if="modal_cargar" :solicitud_id="solicitud.id" :catalogo="catalogo" @close="cerrar_modal_cargar" @update="cerrar_actualizar_modal_cargar" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import Documento from './Documento'
  import Cargar from './Cargar'

  import api from '@/apps/valija/api/solicitudes'
  import apiCatalogos from '@/apps/valija/api/catalogos'

  export default {
    components: {
      Modal, Documento, Cargar
    }
    ,props: {
      solicitud_id: 0
    }
    ,data: function() {
      return {
        solicitud: {
          id: null
          ,documentacion_completa: 0
          ,estatus: 0
          ,documentos: []
          ,snapshots: []
        }
        ,documento: null
        ,catalogo: {
          nombre: null
          ,opciones: []
        }
        ,modal_documento: false
        ,modal_cargar: false
      }
    }
    ,mounted: function() {
      this.obtener_solicitud();
      this.obtener_catalogo();
    }
    ,methods: {
      obtener_solicitud: async function() {
        try {
          this.solicitud = (await api.obtener_solicitud(this.solicitud_id)).data
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_catalogo: async function() {
        try {
          this.catalogo = (await apiCatalogos.buscar_catalogo('tipos_archivos')).data
        }catch(e) {
          return this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
      ,buscar_nombre: function(valor) {
        let nombre = null;
        let opciones = this.catalogo.opciones;

        for(let i=0; i<opciones.length; i++) {
          if (opciones[i].valor == valor) {
            nombre = opciones[i].nombre;
            i=opciones.length;
          }
        }

        return nombre;
      }
      ,mejorar_peso_archivos: function(bytes) {
        if (bytes >= 1000000000)
          return Math.ceil(((bytes / 1000) / 1000) / 1000)+'gb';

        if (bytes >= 1000000)
          return Math.ceil((bytes / 1000) / 1000)+'mb';

        if (bytes > 1000)
          return Math.ceil(bytes / 1000)+'kb';

        return bytes+'bytes';
      }
      ,ver_documento: function(documento) {
        this.documento = documento;
        this.modal_documento = true;
      }
      ,cerrar_modal_documento: function() {
        this.modal_documento = false;
      }
      ,descargar_documento: async function(documento) {
        try {
          let res = (await api.descargar_documento(documento.solicitud_id, documento.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',documento.nombre_original);
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,desbloquear_solicitud: async function() {
        try {
          await api.desbloquear(this.solicitud_id);
          this.obtener_solicitud();
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,bloquear_solicitud: async function() {
        try {
          await api.bloquear(this.solicitud_id);
          this.obtener_solicitud();
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,cerrar_modal_cargar: function() {
        this.modal_cargar = false;
      }
      ,cerrar_actualizar_modal_cargar: function() {
        this.obtener_solicitud();
        this.cerrar_modal_cargar();
      }
    }
  }
</script>