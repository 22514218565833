<template>
  <div>
    <h1 class="col-sm-12 text-right mt-4 mb-4">Solicitudes</h1>

    <DataGrid :config="dataGridConfig" :data="solicitudes" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_solicitudes">Recargar</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="ver_solicitud">Revisar solicitud</button></div>
      </div>
    </DataGrid>

    <Detalle v-if="modal_solicitud" :solicitud_id="solicitud" @close="cerrar_modal"/>
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Detalle from './Solicitud/Detalle'

  import api from '@/apps/valija/api/solicitudes'

  export default {
    components: {
      DataGrid, Detalle
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'solicitudes'
          ,cols: {
            id: 'Solicitud'
            ,documentacion_completa: 'Documentación completa'
            ,estatus: 'Estatus'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            estatus: function(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,documentacion_completa: function(val) {
              return val == 1 ? 'Completa' : 'Faltante';
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,solicitudes: []
        ,solicitud: 0
        ,modal_solicitud: false
      }
    }
    ,mounted: function() {

    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_solicitudes();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_solicitudes: async function() {
        try {
          let res = (await api.obtener_solicitudes(this.options)).data;

          this.solicitudes = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,ver_solicitud: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar una solicitud','error','alert');

        this.solicitud = this.seleccionadas[0].id;
        this.modal_solicitud = true;
      }
      ,cerrar_modal: function() {
        this.seleccionar = false;
        this.modal_solicitud = false;
      }
    }
  }
</script>